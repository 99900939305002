import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL_API } from "../shared/constants/AppOptions";
import { HallsListApiModel } from "./models/hallsListApiModel";
import { FilterSearchParams } from "../modules/Halls/models/FilterSearchParams";
import { HallCheckRequest, HallCheckSearchParams } from "./models/hallInfoApiModel";

export const hallsApiSlice = createApi({
  reducerPath: "hallsApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL_API }),
  tagTypes: ["Halls"],
  endpoints: (builder) => ({
    getHalls: builder.query<
      HallsListApiModel[],
      FilterSearchParams | undefined
    >({
      query: (params) => {
        const url = new URL(`api/halls/${params ? "filtered" : ""}`, BASE_URL_API); // Создаем URL для отфильтрованных залов
        if (params) {
          Object.entries(params).forEach(([key, value]) => {
            if (value) {
              url.searchParams.append(key, String(value)); // Добавляем параметры запроса
            }
          });
        }
        return url.toString();
      },
      providesTags: ["Halls"],
      keepUnusedDataFor: 600,
    }),
    getFilteredHalls: builder.mutation<HallsListApiModel[], FilterSearchParams>(
      {
        query: (params) => {
          const url = new URL("/halls/filtered", BASE_URL_API); // Создаем URL для отфильтрованных залов
          if (params) {
            Object.entries(params).forEach(([key, value]) => {
              if (value) {
                url.searchParams.append(key, String(value)); // Добавляем параметры запроса
              }
            });
          }
          return url.toString(); // Возвращаем полный URL с параметрами
        },
        async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
          try {
            const { data: updatedHalls } = await queryFulfilled;

            const patchResult = dispatch(
              hallsApiSlice.util.updateQueryData(
                "getHalls",
                undefined,
                (draft) => updatedHalls
              )
            );
          } catch {}
        },
      }
    ),
    getHallById: builder.query<HallsListApiModel, string>({
      query: (params) => `/halls/${params}`,
    }),
    checkHall: builder.query<HallCheckRequest, HallCheckSearchParams>({
      query: (params) =>
        `/halls/check_hall/?hall_id=${params.id}&date=${params.date}&time=${params.time}&hours=${params.hours}`,
    }),
  }),
});
export const {
  useGetHallsQuery,
  useLazyGetHallsQuery,
  useGetFilteredHallsMutation,
  useGetHallByIdQuery,
  useLazyCheckHallQuery,
} = hallsApiSlice;
