import SelectComponent from "../../../../ui/Select/Select";
import TimeInput from "../../../../components/Inputs/TimeInput/TimeInput";
import styles from "./styles.module.scss";
import { useState } from "react";
import { SORTOPTIONS } from "../../../../shared/constants/SelectOptions";
import React from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../shared/hooks/reduxTypes";
import { setHour, setMinute, setSort } from "../../slices/FilterSlice";
import TimePicker from "../../../../components/TimePicker/TimePicker";
const ColumnForm = () => {
  const dispatch = useAppDispatch();
  const sortValue = useAppSelector((state) => state.hallsFilterForm.sortValue);
  const hour = useAppSelector((state) => state.hallsFilterForm.hour);
  const minute = useAppSelector((state) => state.hallsFilterForm.minute);
  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 10,
          marginTop: 30,
        }}
        className={styles.container}
      >
        <div className={styles.column}>
          <span className={styles.label}>Время:</span>
          <TimePicker
            onChangeHours={(hour) => {
              dispatch(setHour(hour));
            }}
            onChangeMinutes={(min) => {
              dispatch(setMinute(min));
            }}
            hours={hour}
            minutes={minute}
          />
        </div>

        <div className={styles.column}>
          <span className={styles.label}>Сортировка:</span>
          <SelectComponent
            options={SORTOPTIONS}
            value={sortValue}
            onChange={(v) => {
              dispatch(dispatch(setSort(v)));
            }}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(ColumnForm);
