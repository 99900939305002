import MenuCard from "../../../../ui/MenuCard/MenuCard";
import styles from "./styles.module.scss";
import contImage from "../../../../shared/assets/logo.png";
import React from "react";
import { animated, useSpring } from "@react-spring/web";
import { BaseComponentInterface } from "../../../../shared/models/BaseComponentInterface";
import { FC } from "react";
const MenuList: FC<BaseComponentInterface> = ({ className, children }) => {
  const AnimatedMenuCard = animated(MenuCard);

  // Определяем начальные значения opacity и scale
  const [{ opacity, scale }, api] = useSpring(() => ({
    from: { opacity: 0, scale: 0.5 }, // Начальные значения
    to: { opacity: 1, scale: 1 }, // Конечные значения
    config: { duration: 300, decay: 300 }, // Настройки анимации
  }));

  return <div className={styles.container}>{children}</div>;
};

export default React.memo(MenuList);
