import { Swiper, SwiperSlide } from "swiper/react";
import HistoryCard from "./HistoryCard/HistoryCard";
import styles from "./styles.module.scss";
import {
  useGetUserByTgIDQuery,
  useLazyGetHistoryQuery,
} from "../../../../api/userAPI";
import { useEffect } from "react";
import { TG_ID } from "../../../../shared/constants/AppOptions";
import SkeletonCard from "../../../../ui/SkeletonCard/SkeletonCard";
import { useTelegram } from "../../../../shared/hooks/useTelegram";
import { useSpring, animated } from "@react-spring/web";
const ProfileHistory = () => {
  const { webApp } = useTelegram();
  const { data: userData, isLoading: userDataLoading } = useGetUserByTgIDQuery(
    webApp?.initDataUnsafe?.user?.id
      ? webApp?.initDataUnsafe?.user?.id
      : Number(TG_ID)
  );
  const [getHistory, { data, isLoading }] = useLazyGetHistoryQuery();

  // Определяем начальные значения opacity и scale
  const [{ opacity, scale }, api] = useSpring(() => ({
    from: { opacity: 0, scale: 0.5 }, // Начальные значения
    to: { opacity: 1, scale: 1 }, // Конечные значения
    config: { duration: 500 }, // Настройки анимации
  }));

  useEffect(() => {
    if (userData?.id) {
      getHistory(userData.id);
    }
  }, [userDataLoading]);

  if (isLoading) {
    return (
      <Swiper
        className={styles.swiper}
        slidesPerView={"auto"}
        initialSlide={0}
        spaceBetween={15}
      >
        <SwiperSlide className={styles.slide}>
          <SkeletonCard />
        </SwiperSlide>

        <SwiperSlide className={styles.slide}>
          <SkeletonCard />
        </SwiperSlide>

        <SwiperSlide className={styles.slide}>
          <SkeletonCard />
        </SwiperSlide>
      </Swiper>
    );
  }

  if (data && data.length === 0) {
    return (
      <animated.div style={{ opacity, scale }} className={styles.empty}>
        Вы пока не посещали сауны
      </animated.div>

    );
  }

  const transformDate = (str: string) => {
    const [year, month, date] = str.split("-");

    return `${date}.${month}`;
  };

  return (
    <Swiper
      className={styles.swiper}
      slidesPerView={"auto"}
      initialSlide={0}
      spaceBetween={15}
    >
      {data &&
        data?.map((el) => {
          return (
            <SwiperSlide className={styles.slide}>
              <HistoryCard
                photo={el.photo}
                capacity={el.max_people}
                date={transformDate(el.booked_time)}
                name={el.name}
              />
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
};

export default ProfileHistory;
