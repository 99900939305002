import { SwiperProps, Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { FC, RefObject, useEffect, useRef, useState } from "react";
import MenuList from "../MenuList/MenuList";
import FoodsList from "../FoodsList/FoodsList";
import {
  useGetFoodsQuery,
  useGetGoodsQuery,
  useGetServicesQuery,
} from "../../../../api/productAPI";
import ServiceList from "../ServicesList/ServicesList";
import GoodsList from "../GoodsList/GoodsList";
import "./swiper.scss";
import styles from "./styles.module.scss";

interface SwiperMenuProps extends SwiperProps {
  swiperRef: RefObject<SwiperRef>;
  swiperValue: number;
}

const SwiperMenu: FC<SwiperMenuProps> = ({
  onSlideChange,
  swiperRef,
  swiperValue,
}) => {
  const swiperRefComponent = useRef<HTMLDivElement | null>(null);

  const [swiperHeight, setSwiperHeight] = useState<"auto" | number>("auto");

  const {
    data: foodListData,
    isLoading: foodListIsLoading,
    isError: foodListIsError,
  } = useGetFoodsQuery(undefined, {});

  const {
    data: serviceListData,
    isLoading: serviceListIsLoading,
    isError: serviveListIsError,
  } = useGetServicesQuery(undefined, {});

  const {
    data: goodsListData,
    isLoading: goodsListIsLoading,
    isError: goodsListIsError,
  } = useGetGoodsQuery(undefined, {});

  useEffect(() => {
    // Функция для вычисления расстояния от элемента до низа страницы
    const calculateDistance = () => {
      if (swiperRefComponent.current) {
        const rect = swiperRefComponent.current.getBoundingClientRect();
         
        console.log('window', window.innerHeight)
        // Позиция элемента сверху
        const distanceFromTop = rect.top // Учитываем прокрутку
        // Расстояние до низа страницы
        const distance = window.innerHeight - distanceFromTop;

        setSwiperHeight(distance);
      }
    };

    // Вызываем функцию при монтировании и изменении размера окна
    calculateDistance();
    window.addEventListener("resize", calculateDistance);

    return () => {
      window.removeEventListener("resize", calculateDistance);
    };
  }, []);

  return (
    <div
      ref={swiperRefComponent}
      style={{ flex: "1 0 auto", display: "flex", flexDirection: "column" }}
    >
      <Swiper
        onSlideChange={onSlideChange}
        className="swiper-custom-style"
        ref={swiperRef}
        autoHeight={false}
        cssMode
        speed={250}
        style={{
          margin: 0,
          overflow: "auto",
        }}
        spaceBetween={30}
      >
        <SwiperSlide
          style={{
            height: foodListData?.filtered.length === 0 ? swiperHeight : "auto",
          }}
          className={`${styles.slide} ${
            foodListData && foodListData?.filtered.length === 0 && styles.empty
          }`}
        >
          <FoodsList
            isLoading={foodListIsLoading}
            data={foodListData?.filtered}
            isError={foodListIsError}
          />
        </SwiperSlide>

        <SwiperSlide
          style={{
            height:
              serviceListData?.filtered.length === 0 ? swiperHeight : "auto",
          }}
          className={`${styles.slide} ${
            serviceListData &&
            serviceListData?.filtered.length === 0 &&
            styles.empty
          }`}
        >
          <ServiceList
            data={serviceListData?.filtered}
            isLoading={serviceListIsLoading}
            isError={serviveListIsError}
          />
        </SwiperSlide>

        <SwiperSlide
          style={{
            height:
              goodsListData?.filtered.length === 0 ? swiperHeight : "auto",
          }}
          className={`${styles.slide} ${
            goodsListData && goodsListData.filtered.length === 0 && styles.empty
          }`}
        >
          <GoodsList
            data={goodsListData?.filtered}
            isLoading={goodsListIsLoading}
            isError={goodsListIsError}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default SwiperMenu;
